exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-strapi-post-slug-tsx": () => import("./../../../src/pages/{StrapiPost.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-post-slug-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-post-list-category-tsx": () => import("./../../../src/templates/post-list-category.tsx" /* webpackChunkName: "component---src-templates-post-list-category-tsx" */),
  "component---src-templates-post-list-home-tsx": () => import("./../../../src/templates/post-list-home.tsx" /* webpackChunkName: "component---src-templates-post-list-home-tsx" */),
  "component---src-templates-post-list-tag-tsx": () => import("./../../../src/templates/post-list-tag.tsx" /* webpackChunkName: "component---src-templates-post-list-tag-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

